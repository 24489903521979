(function ($) {
    var Sage = {
        'common': {
            init: function () {

            },
            finalize: function () {
                $('.back-to-top').on("click", function () {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 1450);
                });

                $('.mob-search').on('click', function () {
                    $('.morphsearch').toggleClass('open');
                });

                /*function throttle(fn, threshhold, scope) {
                    threshhold || (threshhold = 250);
                    var last,
                        deferTimer;
                    return function () {
                        var context = scope || this;

                        var now = new Date(),
                            args = arguments;
                        if (last && now < last + threshhold) {
                            // hold on to it
                            clearTimeout(deferTimer);
                            deferTimer = setTimeout(function () {
                                last = now;
                                fn.apply(context, args);
                            }, threshhold);
                        } else {
                            last = now;
                            fn.apply(context, args);
                        }
                    };
                }*/

                /*$("[data-animjs]").each(function () {
                    var $this = $(this);
                    var animetl = anime.timeline({autoplay: false});
                    var properties = {
                        targets: $this[0],
                        direction: 'alternate',
                        easing: "easeOutElastic",
                        duration: 1000,
                        loop: true
                    };

                    // Parse properties from attribute and add them to properties object
                    $.each($this.attr("data-animjs").split(/[,\s]+(?={)/), function (i, value) {
                        $.extend(properties, eval("(" + value + ")"));
                    });

                    animetl.add(properties); // add options object to timeline

                    var parallaxSetup = function () {
                        var body = $('body');
                        switch ($this.attr('class')) {
                            /!*case "element element-half rellax":
                                animetl.seek(animetl.duration / body.outerHeight() * document.documentElement.scrollTop / 60);
                                break;*!/
                            case "element element-quarter blue":
                                animetl.seek(animetl.duration / body.outerHeight() * document.documentElement.scrollTop / 30);
                                break;
                            /!*case "element element-ring rellax":
                                animetl.seek(animetl.duration / body.outerHeight() * document.documentElement.scrollTop * 1);
                                break;
                            case "element element-full rellax":
                                animetl.seek(animetl.duration / body.outerHeight() * document.documentElement.scrollTop / 80);
                                break;*!/
                        }
                    };

                    $(window).on("resize scroll", throttle(parallaxSetup, 50));
                    setTimeout(parallaxSetup, 50);

                });
*/
                var rellax = new Rellax('.rellax', {
                    center: true,
                    wrapper: null,
                    round: true,
                    vertical: true,
                    horizontal: false,
                });

                var c, currentScrollTop = 0,
                    navbar = $('header.banner');

                $(window).scroll(function () {
                    var a = $(window).scrollTop();
                    var b = 45;

                    currentScrollTop = a;

                    if (c < currentScrollTop && a > b + b) {
                        navbar.addClass("scrollUp");
                        setTimeout(function () {
                            navbar.addClass("addedBG");
                        }, 450);
                    } else if (c > currentScrollTop && (a <= b) === false) {
                        navbar.removeClass("scrollUp");
                    }
                    c = currentScrollTop;
                });

                $('.main-ham').on('click', function (e) {
                    $(this).toggleClass('is-active');
                    $(".wrap.container-fluid").toggleClass('is-active');
                    $(".side-nav-primary").toggleClass('is-active');
                    $('html').toggleClass("no-scroll");
                });


                $('a[href*="#"]')
                // Remove links that don't actually link to anything
                    .not('[href="#"]')
                    .not('[href="#0"]')
                    .not('[href="#animatedModal"]')
                    .click(function (event) {
                        // On-page links
                        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                            // Figure out element to scroll to
                            var target = $(this.hash);
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                            // Does a scroll target exist?
                            if (target.length) {
                                if ($('.event-navigation').hasClass('is-active')) {
                                    $('.event-navigation').removeClass('is-active');
                                }
                                // Only prevent default if animation is actually gonna happen
                                event.preventDefault();
                                $('html, body').animate({
                                    scrollTop: target.offset().top
                                }, 1000);
                            }
                        }
                    });
            }
        },
        'home': {
            init: function () {

            },
            finalize: function () {
                $('.slider-carousel').slick({
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4500,
                    pauseOnHover: false,
                    adaptiveHeight: true,

                });

                // $('#mc4wp-form-1').attr('novalidate', 'novalidate');

                $('.teaser-carousel').slick({
                    dots: false,
                    arrows: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    autoplay: false,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: false
                            }
                        }
                    ]
                });

                $('#mc-embedded-subscribe-form').submit(function (e) {
                    var $this = $(this);
                    $.ajax({
                        type: "GET", // GET & url for json slightly different
                        //https://gfm.us17.list-manage.com/subscribe/post-json?u=99515f46fd6fe59b61f6a51eb&id=4fc1892cd3&c=&c=jQuery19008586778567209958_1540379568445&EMAIL=funkysoulzh%40gmail.com
                        url: "https://gfm.us17.list-manage.com/subscribe/post-json?u=99515f46fd6fe59b61f6a51eb&id=4fc1892cd3&c=?",
                        data: $this.serialize(),
                        dataType: 'json',
                        contentType: "application/json; charset=utf-8",
                        // error       : function(err) { alert("1111 - Could not connect to the registration server."); },
                        success: function (data) {
                            if (data.result !== "success") {
                                $('#subscribe-result').html('<p>' + data.msg + '</p>');
                            } else {
                                $('#subscribe-result').html('<p>' + data.msg + '</p>');
                            }
                        }
                    });
                    return false;
                });

                //VIDEO BOX CONTROLLERS
                $('.video-list-item').on('click', function (e) {
                    $('.video-list-container a').removeClass('active');
                    $('.video-list-item.active').removeClass('active');
                    $(this).addClass('active').parent('a').addClass('active');
                });

                var prevYPos = 0;
                $('.yt-arrow.arrow-down').on('click', function (e) {
                    if (prevYPos >= 0) {
                        prevYPos += $('.video-list-item').outerHeight();
                    } else {
                        prevYPos = 0;
                    }

                    $('.video-list-container').animate({
                        scrollTop: prevYPos + 'px'
                    }, 350);
                });

                $('.yt-arrow.arrow-up').on('click', function (e) {
                    if (prevYPos <= $('#videoList').outerHeight()) {
                        prevYPos -= $('.video-list-item').outerHeight();
                    } else {
                        prevYPos = $('#videoList').outerHeight();
                    }

                    $('.video-list-container').animate({
                        scrollTop: prevYPos + 'px'
                    }, 350);
                });
            }
        },
        'about_us': {
            init: function () {

            }
        },
        'mitgliederverzeichnis': {
            finalize: function () {

                var csvData = $('#member-table').data('src');
                var finalData = '';

                $.ajax({
                    url: csvData,
                    dataType: "text",
                    success: function (data) {
                        var arr = CSVToArray(data, ',');
                        createTable(arr);
                    },
                });

                function CSVToArray( strData, strDelimiter ){
                    // Check to see if the delimiter is defined. If not,
                    // then default to comma.
                    strDelimiter = (strDelimiter || ",");

                    // Create a regular expression to parse the CSV values.
                    var objPattern = new RegExp(
                        (
                            // Delimiters.
                            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

                            // Quoted fields.
                            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

                            // Standard fields.
                            "([^\"\\" + strDelimiter + "\\r\\n]*))"
                        ),
                        "gi"
                    );


                    // Create an array to hold our data. Give the array
                    // a default empty first row.
                    var arrData = [[]];

                    // Create an array to hold our individual pattern
                    // matching groups.
                    var arrMatches = null;


                    // Keep looping over the regular expression matches
                    // until we can no longer find a match.
                    while (arrMatches = objPattern.exec( strData )){

                        // Get the delimiter that was found.
                        var strMatchedDelimiter = arrMatches[ 1 ];

                        // Check to see if the given delimiter has a length
                        // (is not the start of string) and if it matches
                        // field delimiter. If id does not, then we know
                        // that this delimiter is a row delimiter.
                        if (
                            strMatchedDelimiter.length &&
                            strMatchedDelimiter !== strDelimiter
                        ){

                            // Since we have reached a new row of data,
                            // add an empty row to our data array.
                            arrData.push( [] );

                        }

                        var strMatchedValue;

                        // Now that we have our delimiter out of the way,
                        // let's check to see which kind of value we
                        // captured (quoted or unquoted).
                        if (arrMatches[ 2 ]){

                            // We found a quoted value. When we capture
                            // this value, unescape any double quotes.
                            strMatchedValue = arrMatches[ 2 ].replace(
                                new RegExp( "\"\"", "g" ),
                                "\""
                            );

                        } else {

                            // We found a non-quoted value.
                            strMatchedValue = arrMatches[ 3 ];

                        }


                        // Now that we have our value string, let's add
                        // it to the data array.
                        arrData[ arrData.length - 1 ].push( strMatchedValue );
                    }

                    // Return the parsed data.
                    return( arrData );
                }

                function createTable(arr) {
                    $('#member-table').DataTable({
                        paging: true,
                        ordering: true,
                        pagingType: "numbers",
                        searching: true,
                        lengthChange: false,
                        data: arr,
                        pageLength: 48,
                        scrollY: 500,
                        scrollCollapse: true,
                        dom: '<"alphabet-container"A><"table-container"lfrtip>',
                        alphabetSearch: {
                            column: 0
                        },
                        language: {
                            "lengthMenu": "Zeige _MENU_ Einträge pro Seite",
                            "info": "Seite _PAGE_ von _PAGES_",
                            "infoEmpty": "Keine Resultate gefunden",
                            "infoFiltered": "(gefiltert von _MAX_ Einträge)",
                            "loadingRecords": "Loading...",
                            "processing":     "Processing...",
                            "search":         "Suche:",
                            "zeroRecords":    "Keine Resultate gefunden",
                            alphabetSearch: {
                                alphabet: '#ABCDEFGHIJKLMNOPQRSTUVWXYZ'
                            },
                            "paginate": {
                                "first":      "Erste",
                                "last":       "Letze",
                                "next":       "Nächste Seite",
                                "previous":   "Vorherige Seite"
                            },
                        }
                    });

                }
            }
        },
        'veranstaltungen': {
            finalize: function () {
                $('.slider-carousel').slick({
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4500,
                    pauseOnHover: false,
                    adaptiveHeight: true,

                });

                $('.slider-container').slick({
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: 'ondemand',
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true
                            }
                        }
                    ]
                }).on('setPosition', function (event, slick) {
                    slick.$slides.css('height', slick.$slideTrack.height() + 'px');
                });

                var $grid = $('.grid').masonry({
                    itemSelector: '.event-item',
                    percentPosition: true
                });

                $grid.imagesLoaded().progress(function () {
                    $grid.masonry('layout');
                });


            }
        },
        'die_gfm': {
            finalize: function () {
                $('.vorstand-item').on('mouseover', function () {
                    /*$imgEl = $(this).find('img');
                    $imgEl.attr('data-org', $imgEl.attr('src'));
                    $imgEl.attr('src', $(this).find('img').attr('data-img'));*/
                }).on('mouseout', function () {
                    /*$imgEl.attr('src', $imgEl.attr('data-org'));*/
                });

                $('.stiftung-item').on('mouseover', function () {
                    /*$imgEl = $(this).find('img');
                    $imgEl.attr('data-org', $imgEl.attr('src'));
                    $imgEl.attr('src', $(this).find('img').attr('data-img'));*/
                }).on('mouseout', function () {
                    /*$imgEl.attr('src', $imgEl.attr('data-org'));*/
                });
            }
        },
        'error404': {
            init: function () {
                var Engine = Matter.Engine,
                    Render = Matter.Render,
                    Runner = Matter.Runner,
                    Composites = Matter.Composites,
                    MouseConstraint = Matter.MouseConstraint,
                    Mouse = Matter.Mouse,
                    World = Matter.World,
                    Bodies = Matter.Bodies;

                // create engine
                var engine = Engine.create(),
                    world = engine.world;

                engine.world.gravity.x = 0;
                engine.world.gravity.y = 0;

                // create renderer
                var render = Render.create({
                    element: document.getElementById('can'),
                    engine: engine,
                    options: {
                        width: window.innerWidth,
                        height: window.innerHeight - 200,
                        hasBounds: false,
                        wireframes: false,
                        background: "#fff"
                    }
                });

                Render.run(render);

                // create runner
                var runner = Runner.create();
                Runner.run(runner, engine);

                // add bodies
                var particleOptions = {
                    friction: 0.05,
                    frictionStatic: 0.1,
                    render: {visible: true}
                };

                var constraintOptions = {
                    render: {visible: false}
                };

                World.add(world, [
                    Composites.softBody(10, 10, 3, 8, 0, 0, true, 10, particleOptions, constraintOptions), //1
                    Composites.softBody(145, 10, 3, 8, 0, 0, true, 10, particleOptions, constraintOptions), //2
                    Composites.softBody(145, 230, 3, 5, 0, 0, true, 10, particleOptions, constraintOptions), //4
                    Composites.softBody(10, 170, 10, 3, 0, 0, true, 10, particleOptions, constraintOptions), //3

                    Composites.softBody(300, 10, 10, 3, 0, 0, true, 10, particleOptions, constraintOptions), //1
                    Composites.softBody(300, 70, 3, 10, 0, 0, true, 10, particleOptions, constraintOptions), //2
                    Composites.softBody(300, 270, 10, 3, 0, 0, true, 10, particleOptions, constraintOptions), //3
                    Composites.softBody(433, 70, 3, 10, 0, 0, true, 10, particleOptions, constraintOptions), //4

                    Composites.softBody(590, 10, 3, 8, 0, 0, true, 10, particleOptions, constraintOptions), //1
                    Composites.softBody(725, 10, 3, 8, 0, 0, true, 10, particleOptions, constraintOptions), //2
                    Composites.softBody(725, 230, 3, 5, 0, 0, true, 10, particleOptions, constraintOptions), //4
                    Composites.softBody(590, 170, 10, 3, 0, 0, true, 10, particleOptions, constraintOptions), //3

                    Bodies.rectangle(400, 0, 1000, 10, {isStatic: true, render: {fillStyle: '#fff'}}), //TOP FLOOR
                    Bodies.rectangle(400, 600, 1000, 10, {isStatic: true, render: {fillStyle: '#fff'}}), //BOTTOM FLOOR
                    /*Bodies.rectangle(1000, 300, 10, 700, {isStatic: true, render: {fillStyle: '#fff'}}),
                    Bodies.rectangle(-100, 300, 10, 600, {isStatic: true, render: {fillStyle: '#fff'}})*/
                ]);

                // add mouse control
                var mouse = Mouse.create(render.canvas),
                    mouseConstraint = MouseConstraint.create(engine, {
                        mouse: mouse,
                        constraint: {
                            stiffness: 0.3,
                            render: {
                                visible: false
                            }
                        }
                    });

                mouse.element.removeEventListener("mousewheel", mouse.mousewheel);
                mouse.element.removeEventListener("DOMMouseScroll", mouse.mousewheel);

                World.add(world, mouseConstraint);

                // keep the mouse in sync with rendering
                render.mouse = mouse;

                // fit the render viewport to the scene
                Render.lookAt(render, {
                    min: {x: 0, y: 0},
                    max: {x: 800, y: 600}
                });

                setTimeout(function () {
                    for (var i = 0; i < 10; i++) {
                        engine.world.gravity.y = (i / 20);
                    }

                    setTimeout(function () {
                        engine.world.gravity.y = 0.055;
                    }, 500);

                }, 2000);
            },
            finalize: function () {

            }
        },
        'single_post': {
            finalize: function () {
                $('.video-slider').slick({
                    dots: true,
                    arrows: false,
                    lazyLoad: 'ondemand',
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                dots: true
                            }
                        }
                    ]
                }).on('afterChange', function (e, o) {
                    //on change slide = do action
                    $('iframe').each(function () {
                        $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
                    });
                });

                $('.gallery-slider').slick({
                    dots: true,
                    arrows: false,
                    lazyLoad: 'ondemand',
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 991,
                            settings: {
                                dots: true
                            }
                        }
                    ]
                });
            }
        },
        'mainevent': {
            init: function () {
            },

            finalize: function () {

                /*window.evenitoAsyncInit = function () {
                    return {
                        //'locale': 'en'
                    };
                };

                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {
                        return;
                    }
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "//eveni.to/assetic/embed.js";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'evenito-embed'));*/


                $('.register_btn').on('click', function (e) {
                    $(this).hide();
                    $('#registration_form').show();
                });

                $('.ham-event').on('click', function (e) {
                    $(this).toggleClass('is-active');
                    $('.event-navigation').toggleClass('is-active');
                });


                //START EVENT NAVIGATION
                var ids = $('main section[id]').map(function () {
                    if (jQuery(this).attr('data-name') !== undefined) {
                        return '<li><a href="#' + this.id + '">' + jQuery(this).attr('data-name') + '</a></li>';
                    }
                }).get();

                jQuery('.event-navigation ul').html(ids);
                /*jQuery('#pagenav a:first-child').addClass('active');


                var section = document.querySelectorAll('section[id]');
                var sections = {};
                var i = 0;

                Array.prototype.forEach.call(section, function(e) {
                    sections[e.id] = e.offsetTop;
                });

                window.onscroll = function() {
                    var scrollPosition = document.documentElement.scrollTop + 80 || document.body.scrollTop;

                    for (i in sections) {

                        if (sections[i] <= scrollPosition) {
                            var pageNavContainer = document.getElementById('pagenav');
                            //if (pageNavContainer.querySelector(".active") != null && pageNavContainer.querySelector(".active").length >= 0) {
                            pageNavContainer.querySelector(".active").setAttribute("class", " ");
                            pageNavContainer.querySelector("a[href*=" + i + "]").setAttribute("class", "active");
                            //}

                        }
                    }
                };*/
                //END EVENT NAVIGATION


                var $grid = $('.grid').masonry({
                    itemSelector: '.grid-item',
                    percentPosition: true
                });

                $grid.imagesLoaded().progress(function () {
                    $grid.masonry('layout');
                });

                var c, currentScrollTop = 0,
                    navbar = $('.event-bar');

                $(window).scroll(function () {
                    var a = $(window).scrollTop();
                    var b = 45;

                    currentScrollTop = a;

                    if (c < currentScrollTop && a > b + b) {
                        navbar.addClass("scrollUp");
                        setTimeout(function () {
                            navbar.addClass("addedBG");
                        }, 450);
                    } else if (c > currentScrollTop && (a <= b) === false) {
                        navbar.removeClass("scrollUp");
                    }
                    c = currentScrollTop;
                });

                $('.speaker-card').on('click', function (e) {
                    var el = $(this);
                    var content = $(this).data('bio');
                    var name = $(this).data('name');


                    console.log(content);

                    if (content.length > 1) {
                        $('#speakermodal').addClass('active');
                        $('.speakerContent-Modal .modal-body').html("<h1>" + name + "</h1>" + "<p>" + content + "</p>");

                        var cssProperties = anime({
                            targets: $('#speakermodal'),
                            opacity: 0.5,
                            left: '240px',
                            backgroundColor: '#FFF',
                            borderRadius: ['0em', '2em'],
                            easing: 'easeInOutQuad'
                        });

                        cssProperties.play();
                    }
                });

                $('span.close').on('click', function (e) {
                    $('#speakermodal').removeClass('active');
                });


            }
        },
        'archive': {
            finalize: function () {

            }
        },
    };

    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            UTIL.fire('common');

            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            UTIL.fire('common', 'finalize');
        }
    };
    $(document).ready(UTIL.loadEvents);

})(jQuery);
